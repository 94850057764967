
















































































































































































































































































import Vue from 'vue';
import Layout from "@/router/layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import Multiselect from "vue-multiselect";

export default Vue.extend({
  components: {
    Layout,
    PageHeader,
    Multiselect
  },
  data() {
    return {
      title: "FAQs",
      items: [
        {
          text: "FAQs",
          active: true
        }
      ],
      value: null,
      message: '',
      options: [
        "Billing",
        "Premium",
        "Payment",
        "Company",
        "Venues",
        "Other",
      ],
    };
  }
});
